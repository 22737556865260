import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@jutro/components';
import { useTranslator } from '@jutro/locale';
import { FacebookService } from '@xengage/gw-portals-facebook-js';
import { useErrors, withErrorBoundary } from '@xengage/gw-portals-error-react';
// eslint-disable-next-line import/no-unresolved
import appConfig from 'app-config';
import styles from './FacebookShare.module.scss';
import messages from './FacebookShare.messages';

export function FacebookShare(props) {
    const { throwReactError } = useErrors();
    const { facebookShareConfig = {} } = appConfig;

    useEffect(() => {
        let configForFacebook;
        FacebookService.getClient().then((fbClient, fbConfig) => {
            fbClient.XFBML.parse();
            configForFacebook = fbConfig;
        }).catch(throwReactError);

        return function cleanup() {
            FacebookService.removeClient(configForFacebook);
        };
    // only execute once
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const { buttonText, text } = props;

    const triggerShare = useCallback(() => {
        // prepare share dialog data
        const { shareDialog } = facebookShareConfig;
        if (buttonText) {
            shareDialog.buttonText = buttonText;
        }
        if (text) {
            shareDialog.text = text;
        }

        // invoke share
        window.FB.ui(
            Object.assign(shareDialog, {
                method: 'share'
            })
        );
    }, [buttonText, facebookShareConfig, text]);

    const translator = useTranslator();

    return (
        <div className={styles.facebookContent}>
            <div className={styles.facebookShare}>
                <p>{translator(text)}</p>

                <Button onClick={triggerShare} icon="mi-share">
                    {translator(buttonText)}
                </Button>
            </div>
        </div>
    );
}

FacebookShare.propTypes = {
    text: PropTypes.shape({
        id: PropTypes.string,
        defaultMessage: PropTypes.string
    }),
    buttonText: PropTypes.shape({
        id: PropTypes.string,
        defaultMessage: PropTypes.string
    })
};

FacebookShare.defaultProps = {
    text: messages.description,
    buttonText: messages.facebookMessageShare
};

export default withErrorBoundary({ resetError: false })(FacebookShare);
